<template>
  <CModal :show="isModalOpen" :centered="true">
    <div slot="header" />
    <div slot="header-wrapper" />
    <div slot="body-wrapper" class="align-center">
      <h5 id="empty-file-modal-header">
        {{ $t("DISBURSEMENTS.FILE_EMPTY_MODAL.HEADER") }}
      </h5>
      <div class="padding-around">
        {{ $t("DISBURSEMENTS.FILE_EMPTY_MODAL.MESSAGE") }}
      </div>
      <br />
      <CButton
        id="empty-file-modal-button"
        color="durianprimary"
        @click="toggleModal()"
      >
        {{ $t("DISBURSEMENTS.FILE_EMPTY_MODAL.UNDERSTAND_BUTTON") }}
      </CButton>
    </div>
    <div slot="footer" />
  </CModal>
</template>

<script>
export default {
  name: "FileEmptyMessageModal",
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
  },
};
</script>