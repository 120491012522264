<template>
  <div v-page-access="permissions.disbursementCreate">
    <CRow>
      <CCol col="12" xl="12">
        <balance-card :hasButtonTopup="true" />
        <CCard>
          <CCardHeader>
            <strong>
              <span class="header-text">
                {{ $t("DISBURSEMENTS.CREATE_BATCH.TITLE") }}
              </span>
              </strong>
          </CCardHeader>
          <CCardBody>
            <CRow class="mt-3">
              <CCol col="5">
                <CRow>
                  <CCol sm="12">
                    <CInput
                      class="col-12 px-0"
                      :label="getBatchNameLabel()"
                      v-model="name"
                      :is-valid="isSubmitClicked ? validateData(name) : null"
                      :invalid-feedback="
                        $t('DISBURSEMENTS.VALIDATION_ERRORS.BATCH_NAME_EMPTY')
                      "
                    />
                  </CCol>
                  <CCol sm="12">
                    <CInput
                      class="col-12 px-0"
                      v-model="description"
                      :label="getBatchDescriptionLabel()"
                    />
                  </CCol>
                  <CSelect
                    id="approvers"
                    :label="getApproverLabel()"
                    @change="(event) => updateApprover(event)"
                    :options="approverOptions"
                    class="col-12"
                    :value="approver"
                  />
                </CRow>
              </CCol>
              <CCol col="7" class="padding-around">
                <CCard class="upload-box p-4" :style="uploadBoxStyle">
                  <CCardBody>
                    <div v-if="file" class="file-name-container">
                      <img src="../../assets/img/excel.svg" />
                      {{ file.name }}
                      <span
                        class="delete-file pointer-on-hover"
                        @click="resetEntries"
                      >
                        {{ this.$t("COMMON.DELETE") }}
                      </span>
                    </div>
                    <span
                      @drop="dropHandler"
                      @dragover="filedragover = true"
                      @dragleave="filedragover = false"
                    >
                      <input
                        type="file"
                        id="fileInput"
                        class="opacity-0"
                        ref="file"
                        accept=".xlsx, .csv"
                        @change="setFile"
                        v-if="!file"
                      />
                    </span>
                    <div class="drop-text" v-if="!file">
                      <strong>
                        {{
                          this.$t("DISBURSEMENTS.CREATE_BATCH.DRAG_AND_DROP")
                        }}
                      </strong>
                      or
                      <span class="browse-button">
                        <CButton color="durianprimary" @click="openFolderPopup">
                          {{
                            this.$t("DISBURSEMENTS.CREATE_BATCH.BROWSE_FILE")
                          }}
                        </CButton>
                      </span>
                    </div>
                  </CCardBody>
                </CCard>
                <p class="h6">
                  <strong>
                    {{ $t("DISBURSEMENTS.CREATE_BATCH.INSTRUCTION_TITLE") }}
                  </strong>
                </p>
                <ol class="px-3 instructions">
                  <li class="py-1">
                    <i18n
                      path="DISBURSEMENTS.CREATE_BATCH.DOWNLOAD_INSTRUCTION"
                      tag="span"
                      class="ml-2"
                    >
                      <a
                        class="text-primary"
                        href="templates/template_batch_disbursement_durianpay.xlsx"
                      >
                        Excel
                      </a>
                      <a
                        class="text-primary"
                        href="templates/template_batch_disbursement_durianpay.csv"
                      >
                        CSV
                      </a>
                    </i18n>
                  </li>
                  <li class="py-1">
                    <span class="ml-2">{{
                      $t("DISBURSEMENTS.CREATE_BATCH.FILL_INSTRUCTION")
                    }}</span>
                  </li>
                  <li class="py-1">
                    <span class="ml-2">{{
                      $t("DISBURSEMENTS.CREATE_BATCH.SAVE_INSTRUCTION")
                    }}</span>
                  </li>
                  <li class="py-1">
                    <span class="ml-2">{{
                      $t("DISBURSEMENTS.CREATE_BATCH.UPLOAD_INSTRUCTION")
                    }}</span>
                  </li>
                </ol>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="mb-5">
      <CCol class="col-12">
        <CButton
          color="durianprimary"
          :disabled="!file"
          size="lg"
          class="float-right ml-3 px-5"
          @click="submitFile()"
        >
          {{ $t("DISBURSEMENTS.CREATE_BATCH.SUBMIT_FILE") }}
        </CButton>
        <CButton
          @click="goTo('/disbursements')"
          size="lg"
          class="float-right ml-3 btn-cancel"
        >
          {{ $t("COMMON.CANCEL") }}
        </CButton>
      </CCol>
    </CRow>
    <file-empty-message-modal
      :isModalOpen="isFileEmptyMessageModalOpen"
      :toggleModal="toggleFileEmptyMessageModal"
    />
  </div>
</template>

<script>
const CSV = "csv";
const XLSX = "xlsx";

import {
  getDisbursementApprovers,
  uploadDisbursement,
  submitDisbursementFile,
  getDisbursementAdmins,
} from "@/api/disbursement.api.js";
import BalanceCard from "./components/BalanceCard.vue";
import FileEmptyMessageModal from "./components/modals/FileEmptyMessageModal.vue";

export default {
  components: { BalanceCard, FileEmptyMessageModal },
  name: "CreateBatchDisbursements",
  data() {
    return {
      approverOptions: [],
      file: null,
      file_path: null,
      filedragover: false,
      loading: false,
      name: null,
      description: null,
      approver: null,
      isSubmitClicked: false,
      isFileEmptyMessageModalOpen: false,
      disbursementID: null,
      totalAmount: null,
      totalTransactions: null,
    };
  },
  methods: {
    getBatchNameLabel() {
      return this.$t("DISBURSEMENTS.CREATE_BATCH.BATCH_NAME");
    },
    getBatchDescriptionLabel() {
      return this.$t("DISBURSEMENTS.CREATE_BATCH.BATCH_DESCRIPTION");
    },
    getApproverLabel() {
      return this.$t("DISBURSEMENTS.CREATE_BATCH.APPROVER");
    },
    toggleFileEmptyMessageModal() {
      this.isFileEmptyMessageModalOpen = !this.isFileEmptyMessageModalOpen;
    },
    setFile(event) {
      if (!this.validateFile(event.target.files[0])) {
        return;
      }
      this.file = event.target.files[0];
      this.uploadAndSubmitFile();
    },
    resetEntries() {
      this.file = null;
      this.description = null;
      this.name = null;
    },
    openFolderPopup() {
      const el = document.getElementById("fileInput");
      el.click();
    },
    dropHandler() {
      this.filedragover = false;
    },
    async getApprovers() {
      try {
        const response = await getDisbursementApprovers();
        if (!this.verifyHttpResponse(response)) {
          const data = response.data.data;
          this.approverOptions = data.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          this.approver = this.approverOptions[0].value;
        } else {
          this.showToaster(this.$t("DISBURSEMENTS.ERRORS.FAIL_APPROVER_LIST"));
        }
        const adminResponse = await getDisbursementAdmins();
        if (!this.verifyHttpResponse(adminResponse)) {
          const adminData = adminResponse.data.data;
          let admins = adminData.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          this.approverOptions = this.approverOptions.concat(admins);
        } else {
          this.showToaster(this.$t("DISBURSEMENTS.ERRORS.FAIL_APPROVER_LIST"));
        }
      } catch (error) {
        this.showToaster(this.$t("DISBURSEMENTS.ERRORS.FAIL_APPROVER_LIST"));
      }
    },
    validatePayload(payload) {
      return payload.name && payload.name.length !== 0 && payload.file_path;
    },
    updateApprover(event) {
      const value = event.target.value;
      this.approver = value;
    },
    async submitFile() {
      try {
        const { name, description, file_path } = this;
        const payload = {
          name,
          description,
          approved_by: parseInt(this.approver, 10),
          file_path,
        };
        if (!this.validatePayload(payload)) {
          this.showToaster(
            this.$t("DISBURSEMENTS.ERRORS.MANDATORY_FIELD_EMPTY")
          );
          return;
        }
        const submitResponse = (await submitDisbursementFile(payload)).data
          .data;
        this.disbursementID = submitResponse.id;
        this.totalAmount = submitResponse.total_amount;
        this.totalTransactions = submitResponse.total_disbursements;
        this.showSuccessToaster(
          this.$t("DISBURSEMENTS.SUCCESS.FILE_SUBMISSION_SUCCESS")
        );
        this.goTo("/disbursements/info/" + this.disbursementID);
      } catch (error) {
        this.showToaster(this.$t("DISBURSEMENTS.ERRORS.ERROR_SUBMITTING_FILE"));
      }
    },
    validateFile(file) {
      const fileNameBreak = file.name.split(".");
      const type = fileNameBreak[fileNameBreak.length - 1];
      if (type !== CSV && type !== XLSX) {
        this.showToaster(this.$t("DISBURSEMENTS.ERRORS.FORMAT_NOT_SUPPORTED"));
        return false;
      }
      return true;
    },
    async uploadAndSubmitFile() {
      this.loading = true;
      this.isSubmitClicked = true;
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("file", this.file);
        if (this.file) {
          const response = (await uploadDisbursement(bodyFormData)).data.data;
          const path = new URL(response.file_path).pathname;
          this.file_path = path;
          this.name = response.batch_name;
          this.description = response.description;
        }
      } catch (error) {
        this.file = null;
        if (error.response && error.response.status === 422) {
          this.isFileEmptyMessageModalOpen = true;
          return;
        }
        this.showToaster(this.$t("DISBURSEMENTS.ERRORS.ERROR_UPLOADING_DATA"));
      }
    },
    validateData(value) {
      return value !== "" && value !== null;
    },
  },
  computed: {
    uploadBoxStyle() {
      if (this.filedragover) {
        return {
          opacity: "0.5",
        };
      }
    },
  },
  mounted() {
    this.getApprovers();
  },
};
</script>
<style scoped>
.instructions {
  list-style: none;
  counter-reset: item;
  margin-left: -3%;
}
.instructions li {
  counter-increment: item;
  margin-bottom: 5px;
}
.instructions li:before {
  content: counter(item);
  background: rgba(114, 92, 246, 0.27);
  border-radius: 100%;
  color: #725cf6;
  width: 1.4em;
  text-align: center;
  display: inline-block;
}
.upload-box {
  border: 1px dashed #725cf6;
  background-color: #f8f8f8;
}
.drop-text {
  margin-top: -110px;
  margin-bottom: -15px;
  text-align: center;
}
.btn-cancel {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  height: 36px;
  line-height: 20px;
}
#table {
  border: 1px #eee solid;
  border-radius: 8px;
}
#button {
  margin-bottom: 5%;
  margin-top: 3%;
  right: 5%;
}
.towards-right {
  font-size: 12px;
  color: blue;
  margin-left: 88%;
}
.opacity-0 {
  opacity: 0;
}
.padding-around {
  padding-left: 5%;
  padding-right: 4%;
}
#fileInput {
  width: 120%;
  height: 100px;
  position: relative;
  bottom: 40px;
  right: 40px;
}
.file-name-container {
  text-align: left;
  background: white;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin-top: -3%;
  margin-bottom: -3%;
}
.delete-file {
  position: absolute;
  right: 12%;
  color: #725cf6;
}
.browse-button {
  padding-left: 4px;
}
.header-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #4E4F52;
}
</style>